import {
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  List,
  ListItemDecorator,
  ListItemButton,
  ListItem,
  Alert,
  Drawer,
  Chip,
  IconButton
} from '@mui/joy';
import {
  Settings,
  ChatBubble,
  Image,
  AssignmentInd,
  LibraryBooks,
  Menu,
  Favorite,
  MonitorHeart
} from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import meditate from '../lottie/meditate.json';
import { playCreatorMessage } from '../modules/easter-egg';
import { useState } from 'react';

import { useUser } from '../context/user';

const App = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [showDrawer, setShowDrawer] = useState(false);

  const goToPage = page => {
    navigate(page);
    setShowDrawer(false);
  };

  return (
    <>
      <Stack
        sx={{ position: 'fixed', top: 0, width: '100%', p: 1 }}
        direction="row"
        justifyContent={'space-between'}
      >
        <IconButton size="lg" onClick={() => setShowDrawer(true)}>
          <Menu />
        </IconButton>
      </Stack>
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Typography level="h3" sx={{ mb: 1 }}>
            Collective Council
          </Typography>
          <Typography level="body-md">{user?.name}</Typography>
          <Typography level="body-md">{user?.email}</Typography>
          <Divider sx={{ my: 2 }} />
          <List sx={{ flexGrow: 1 }}>
            <ListItem>
              <ListItemButton onClick={() => goToPage('chat')}>
                <ListItemDecorator>
                  <ChatBubble />
                </ListItemDecorator>
                Chat
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('images')}>
                <ListItemDecorator>
                  <Image />
                </ListItemDecorator>
                Image Generation{' '}
                <Chip color="primary" size="sm" variant="outlined">
                  Sage
                </Chip>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('positive-reframe')}>
                <ListItemDecorator>
                  <Favorite />
                </ListItemDecorator>
                Positive Reframe
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('healthy-messages')}>
                <ListItemDecorator>
                  <MonitorHeart />
                </ListItemDecorator>
                Healthy Messages
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('proofreader')}>
                <ListItemDecorator>
                  <LibraryBooks />
                </ListItemDecorator>
                Proofreader
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('resume')}>
                <ListItemDecorator>
                  <AssignmentInd />
                </ListItemDecorator>
                Résumé Tailoring
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('settings')}>
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                Settings
              </ListItemButton>
            </ListItem>
          </List>
          <Box onClick={() => playCreatorMessage(`hi ${user.name}`)}>
            <Lottie animationData={meditate} style={{ height: 333 }} />
          </Box>
          <Alert
            variant="soft"
            endDecorator={
              <Button
                size="sm"
                href="https://contact.golightlyplus.com"
                component="a"
                variant="plain"
                target="_blank"
                color="info"
              >
                Contact
              </Button>
            }
          >
            <Stack>
              <Typography level="body-sm">Collective Council</Typography>
              <Typography level="body-xs">
                (version: {process.env.REACT_APP_VERSION})
              </Typography>
              <Typography level="body-xs">
                © Golightly+ {new Date().getFullYear()}
              </Typography>
            </Stack>
          </Alert>
        </Box>
      </Drawer>
      <Outlet />
    </>
  );
};

export default App;
