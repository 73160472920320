import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet
} from 'react-router-dom';

import './modules/firebase';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import Settings from './components/Settings';
import Chat from './components/Chat';
import Images from './components/Images';
import Resume from './components/Resume';
import Proofreader from './components/Proofreader';
import PositiveReframe from './components/PositiveReframe';
import ErrorPage from './components/ErrorPage';
import { UserProvider } from './context/user';
import HealthyMessages from './components/HealthyMessages';

const App = () => {
  return (
    <CssVarsProvider defaultMode="system">
      <CssBaseline />
      <UserProvider>
        <Outlet />
      </UserProvider>
    </CssVarsProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to="chat" replace /> },
          {
            path: 'chat',
            element: <Chat />
          },
          {
            path: 'images',
            element: <Images />
          },
          {
            path: 'positive-reframe',
            element: <PositiveReframe />
          },
          {
            path: 'healthy-messages',
            element: <HealthyMessages />
          },
          {
            path: 'proofreader',
            element: <Proofreader />
          },
          {
            path: 'resume',
            element: <Resume />
          },
          {
            path: 'settings',
            element: <Settings />
          }
        ]
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
