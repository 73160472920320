import React, { useState } from 'react';
import {
  Card,
  Typography,
  Stack,
  Textarea,
  Button,
  LinearProgress,
  Box,
  IconButton,
  Divider,
  Snackbar,
  Grid
} from '@mui/joy';
import { ContentCopy, MonitorHeart, Check } from '@mui/icons-material';
import { getClaudeResponse } from '../modules/firebase';
import Markdown from 'react-markdown';

const HealthyMessages = () => {
  const [message, setMessage] = useState('');
  const [analysis, setAnalysis] = useState('');
  const [querying, setQuerying] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const analyzeMessage = async () => {
    try {
      setQuerying(true);
      const response = await getClaudeResponse(
        [
          {
            role: 'user',
            content: `Analyze this message and provide gentle, constructive feedback to make it more positive and empowering while maintaining its intent:

             "${message}"

             If you find any areas for improvement, suggest specific alternative phrasings. Consider unintentional manipulation, self-deprecation, and unclear communication.

             Keep your response friendly and concise. If the message is already healthy, acknowledge its strengths briefly.`
          }
        ],
        'You are an expert in compassionate communication and emotional intelligence.'
      );

      setAnalysis(response);
      console.log(response);
      setQuerying(false);
    } catch (err) {
      console.error(err);
      setQuerying(false);
    }
  };

  return (
    <Grid container m={3} justifyContent="center">
      <Grid xs={12} mt={8}>
        <Card>
          <Typography
            level="h2"
            startDecorator={<MonitorHeart />}
            sx={{ mb: 2 }}
          >
            Healthy Messages
          </Typography>

          <Typography level="body-md" sx={{ mb: 2 }}>
            Analyse your message for healthy, positive communication. Get
            feedback on emotional tone, unintentional patterns, and suggestions
            for improvement.
          </Typography>

          <Stack spacing={2}>
            <Textarea
              minRows={4}
              placeholder="Type or paste your message here..."
              value={message}
              onChange={e => setMessage(e.target.value)}
            />

            <Button
              disabled={!message.trim() || querying}
              onClick={analyzeMessage}
              size="lg"
            >
              Analyse Message
            </Button>

            {querying && <LinearProgress />}

            {analysis && (
              <Card variant="outlined">
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography level="title-lg">Analysis Results</Typography>
                    <IconButton
                      variant="plain"
                      color="neutral"
                      onClick={() => {
                        navigator.clipboard.writeText(analysis);
                        setShowSnackbar('Analysis copied to clipboard');
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Markdown>{analysis}</Markdown>
                  </Box>
                </Stack>
              </Card>
            )}
          </Stack>
        </Card>
        {showSnackbar && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            color="success"
            size="sm"
            variant="outlined"
            startDecorator={<Check />}
            open={showSnackbar}
            onClose={() => setShowSnackbar(null)}
          >
            {showSnackbar}
          </Snackbar>
        )}
      </Grid>
    </Grid>
  );
};

export default HealthyMessages;
